// todo get this from sanity
export const commonColors = {
  primaryColor: '#8d1c1c',
  primaryColorLight: '#b5b5ff',
  primaryColorDark: '#8d1c1c',
  secondaryColor: '#004609',
  secondaryColorLight: '#89e06c',
  secondaryColorDark: '#004609',
  errorColor: '#d50000',
  black: '#000000',
  paperBlack: '#1f1b1a',
  white: '#f1f1f1',
  paperWhite: '#f2f7f7', //also try f6ffff
}
